import React from 'react'
const Introduction = ({title, subtitle, caption}) =>{
    return(
        <div className="introduction">
            <div className="spacer m-spacer"></div>
            <div className="title-area p20 text-center max-500 ma">
                <h1 className="m0">{title}</h1>
                { subtitle ? <p className="h3 m0 mt40">{subtitle}</p> : ''} 
                { caption ? <p className="sainte m0 mt20">{caption}</p> : ''} 
            </div>
            <div className="spacer m-spacer"></div>
        </div>
    )
}
export default Introduction