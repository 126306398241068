import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
import {kebabCase} from 'lodash'

const TeamMembers = ({team}) =>{
    const members = team.edges.map((member, index) => {
        return (  
        <div className="team-member p20 flex m-wrap" key={index} style={{backgroundColor:member.node.background?.rgb}}>
            <div className="w-50 m-100 pr20 mp0 m-b0 br1">
                <div className="ratio-2-1 pos-rel">
                    <GatsbyImage image={member.node.featureImage?.gatsbyImageData} alt={member.node?.name} className="bg-image" /> 
                </div>
            </div>
            <div className={"w-50 m-100 mp0 m-b0 pl20 flex justify-content " + (member.node?.textInWhite === true ?  'text-white' : '')}>
                <div className="max-350 m-mwa m-mt40 w-100 align-self--center ma">
                    <p className="h1 m0 mb20">{member.node?.name}</p>
                    <p className="uppercase m0 mb20">{member.node?.companyPosition}</p>
                    <div className="content mb40 sainte">
                        {member.node.about} 
                    </div>
                    <div className="flex m-mb40">
                        <a className="button mr20" href={'/team/' + kebabCase(member.node?.name).toLowerCase().trim() }>Read More</a>
                        <a className="button outline" href={"mailto:"+member.node?.email}>Email {member.node?.name}</a>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    );
    return(
    <div className="team-members"> 
        {members}
    </div>
    )}
export default TeamMembers