import React from 'react'
const Testimonial = ({text, author, background}) =>{
    return(
        <div className="testimonial--single p20 text-center" style={{'background': background}}>
            <div className="spacer m-spacer"></div>
            <p className="h1 m0 mb40">Testimonial</p>
            <div className="inner ma max-650">
                <div className="testimonial h3 mb40">
                  "{text}"  
                </div>
                <p className="m0 uppercase">{author}</p> 
            </div>
            <div className="spacer m-spacer"></div>
        </div>
    )
}
export default Testimonial