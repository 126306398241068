import React from 'react'
import { Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'

const TextBanner = ({link, title, text, topIcon, copy, button, buttonWhite, background}) =>{
    return(
    <div className="text-banner" style={{'background': background}}> 
        <div className="spacer m-hide"></div>
        <div className="max-650 p20 ma text-center m-mb40 m-mt40 m-inline">
            {( topIcon ?<div className='text-center mb40'><div className='award-icon'><GatsbyImage image={topIcon?.gatsbyImageData} alt="Icon" className="bg-image contain" /></div></div>:'')}
            {title ? <p className="h1 m0 mb40">{title}</p> : null }
            <div className="inner h3 mb40">{text}</div>
            <div className="inner sainte mb40">{copy}</div>
            {button ? <Link to={link} className={buttonWhite ? 'button white' : 'button' }>{button}</Link> : null}  
        </div>
        <div className="spacer m-hide"></div>
    </div>
    )
}
export default TextBanner