import * as React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import Testimonial from "../components/testimonial"
import TextBanner from "../components/textBanner"
import Team from "../components/team"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const TeamPage = ({data}) => {
  const seoInfo = data.datoCmsTeamPage
  return (
    <Layout>
      <Seo description={seoInfo.metaDescription} title={seoInfo.metaTitle} />

      <Introduction title="Our Team" subtitle={data.datoCmsTeamPage.ourTeamTopText} caption={data.datoCmsTeamPage.ourTeamBottomText} /> 
      
      <Team team={data.allDatoCmsTeamMember} />  

      <TextBanner button="Get Started" buttonWhite="true" link="https://lewisandtrovas.settify.com.au/landing?referral=homepage" background="#A69EA9" title="Get started with our team today" copy={data.datoCmsTeamPage.getStartedWithOurTeamToday} />

      <Testimonial author={data.datoCmsTeamPage.testimonialName} text={data.datoCmsTeamPage.testimonialQuotation} />
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsTeamPage {
    testimonialName
    testimonialQuotation
    ourTeamBottomText
    ourTeamTopText
    getStartedWithOurTeamToday
    metaTitle
    metaDescription

  }
    allDatoCmsTeamMember(sort: {fields: position, order: ASC}) {
      edges {
        node {
          name
          about
          email
          textInWhite
          companyPosition
          background {
            rgb
          }
          featureImage  {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default TeamPage
